const initImageCompare = () => {
  const THUMB_WIDTH = 50;
  const SLIDE_OFFSET = 16;
  const compareSlider = document.querySelectorAll('.n-js-image-compare');

  if (!compareSlider.length) {
    return;
  }

  const compareImage = (item) => {
    const thumb = item.querySelector('.n-js-image-compare-thumb');
    const image = item.querySelector('.n-js-image-compare-before');

    const isSlider = item.closest('.n-restoration-compare--slider') ? true : false;

    // проверка на тач-устройство
    let isTouchCapable = 'ontouchstart' in window ||
    window.DocumentTouch && document instanceof window.DocumentTouch ||
    navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0;

    image.style.clipPath = 'inset(0 50% 0 0)';

    let clicked = false;
    const itemRect = item.getBoundingClientRect();

    const getOffset = (position) => {
      const elIndex = item.dataset.index;
      const elWidth = itemRect.width;
      let newPosition = position - (elWidth * elIndex) - SLIDE_OFFSET * elIndex;

      return newPosition;
    };

    const getCursorPosition = (evt) => {
      let isMobile = window.matchMedia('(max-width: 767px)').matches;
      let itemX = itemRect.left;

      if (isMobile && isSlider) {
        itemX = getOffset(itemX);
      }

      let x;

      if (isTouchCapable) {
        x = evt.touches[0].clientX - itemX;
      } else {
        x = evt.clientX - itemX;
      }

      return x;
    };

    const slideFinish = (evt) => {
      clicked = false;

      window.removeEventListener('mousemove', slideMove);
      window.removeEventListener('touchmove', slideMove);
    };

    const slide = (pos) => {
      let value = 100 - (pos / itemRect.width) * 100;

      thumb.style.left = `${100 - value}%`;
      image.style.clipPath = `inset(0 ${value}% 0 0)`;
    };

    const slideMove = (evt) => {
      if (!clicked) {
        return;
      }

      let cursorPosition = getCursorPosition(evt);

      if (cursorPosition < THUMB_WIDTH / 2) {
        cursorPosition = THUMB_WIDTH / 2;
      }

      if (cursorPosition > itemRect.width - THUMB_WIDTH / 2) {
        cursorPosition = itemRect.width - THUMB_WIDTH / 2;
      }

      slide(cursorPosition);
    };

    const slideReady = (evt) => {
      evt.preventDefault();
      clicked = true;

      window.addEventListener('mousemove', slideMove);
      window.addEventListener('touchmove', slideMove);
    };

    thumb.addEventListener('mousedown', slideReady);
    thumb.addEventListener('touchstart', slideReady);
    window.addEventListener('mouseup', slideFinish);
    window.addEventListener('touchend', slideFinish);
  };

  document.addEventListener('DOMContentLoaded', () => {
    compareSlider.forEach((item) => {
      compareImage(item);
    });
  });

  document.addEventListener('resize', () => {
    compareSlider.forEach((item) => {
      compareImage(item);
    });
  });
};

export {initImageCompare};
