const switcher = () => {
  const switchers = document.querySelectorAll('.n-js-switcher');

  if (switchers) {
    for (let item of switchers) {
      const target = item.querySelector('.n-switcher__slider');
      const links = item.querySelectorAll('.n-switcher__opt');
      const chosen = item.querySelector('[checked]').parentNode;

      chosen.classList.add('active');
      let width = chosen.getBoundingClientRect().width;
      let left = chosen.offsetLeft;

      target.style.width = `${width}px`;
      target.style.transform = `translateX(${left}px)`;

      const mouseenterFunc = function () {
        if (!this.parentNode.classList.contains('active')) {
          for (let i = 0; i < links.length; i++) {
            if (links[i].parentNode.classList.contains('active')) {
              links[i].parentNode.classList.remove('active');
            }
          }

          this.parentNode.classList.add('active');

          width = this.getBoundingClientRect().width;
          left = this.parentNode.offsetLeft;

          target.style.width = `${width}px`;
          target.style.transform = `translateX(${left}px)`;
        }
      };

      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', mouseenterFunc);
      }

      const resizeFunc = function () {
        const active = item.querySelector('.n-switcher__label.active');

        if (active) {
          left = active.offsetLeft;
          width = active.getBoundingClientRect().width;

          target.style.transform = `translateX(${left}px)`;
          target.style.width = `${width}px`;
        }
      };

      window.addEventListener('resize', resizeFunc);
    }
  }
};

export {switcher};
