const toggleBtn = () => {
  const toggleBtns = document.querySelectorAll('.n-js-toggle-btn');
  let binded;

  if (!toggleBtns) {
    return;
  }

  const toggle = (btn, related) => {
    let pressed = btn.getAttribute('aria-pressed') === 'true';
    btn.setAttribute('aria-pressed', String(!pressed));

    binded = closeOnOut.bind(null, btn, related);
    if (pressed === false) document.addEventListener('click', binded);
  };

  const closeOnOut = (btn, related, e) => {
    if (e.target !== btn && !btn.contains(e.target) && e.target !== related && !related.contains(e.target)) {
      btn.setAttribute('aria-pressed', 'false');
      document.removeEventListener('click', binded);
    }
  };

  for (let i of toggleBtns) {
    const related = i.parentNode.querySelector('.n-js-toggle-btn-related');
    i.addEventListener('click', toggle.bind(null, i, related));
  }
};

export {toggleBtn};

