const sliderMobile = () => {
  const sliders = document.querySelectorAll('.n-js-slider-mobile');
  const paddingMobile = 16;
  const mobileBr = 768;

  if (sliders) {
    let breakpoint = window.matchMedia(`(min-width: ${mobileBr}px)`);

    sliders.forEach((slider) => {

      let mySwiper;
      let wrapper = slider.querySelector('.n-slider-mobile__wrapper');
      let slides = slider.querySelectorAll('.n-slider-mobile__slide');
      let isGallery = slider.classList.contains('n-slider-mobile--gallery');
      let gap;
      isGallery ? gap = paddingMobile * 2 : gap = paddingMobile;

      const breakpointChecker = function() {
        if (breakpoint.matches === true) {
          if (mySwiper !== undefined) mySwiper.destroy(true, true);
          removeSwiperClasses();
          return;
        }
        else if (breakpoint.matches === false) {
          return initSwiper();
        }
      };

      const addSwiperClasses = function () {
        slider.classList.add('swiper-container');
        wrapper.classList.add('swiper-wrapper');
        for (let i of slides) {
          i.classList.add('swiper-slide');
        }
      };

      const removeSwiperClasses = function () {
        slider.classList.remove('swiper-container');
        wrapper.classList.remove('swiper-wrapper');
        for (let i of slides) {
          i.classList.remove('swiper-slide');
        }
      };

      const initSwiper = () => {
        let btns = slider.querySelectorAll('.n-js-slider-controls');
        let id = '#' + slider.id;
        let setLoop = false;
        let paginationOpt = false;
        let perView = 'auto';
        let center = false;
        let autoheight = false;
        slider.classList.contains('n-js-slider-mobile--no-loop') ? setLoop = false : setLoop = true;

        if (slider.classList.contains('n-js-slider-mobile--next-visible')) {
          perView = 1.5;
        } else if (slider.classList.contains('n-js-slider-mobile--both-visible')) {
          perView = 1.5;
          center = true;
        }
        if (slider.classList.contains('n-js-slider-mobile--pagination')) {
          paginationOpt = {
            el: '.n-js-slider-mobile-pagination',
            type: 'bullets',
            clickable: true,
          };
        }
        if (slider.classList.contains('n-js-slider-mobile-autoheight')) {
          autoheight = true;
        }

        addSwiperClasses();

        mySwiper = new Swiper(id, {
          loop: setLoop,
          spaceBetween: gap,
          navigation: {
            prevEl: btns[0],
            nextEl: btns[1],
          },
          speed: 400,
          slidesPerView: perView,
          centeredSlides: center,
          observeParents: true,
          observer: true,
          grabCursor: true,
          pagination: paginationOpt,
          autoHeight: autoheight,
        });
      };

      breakpoint.addListener(breakpointChecker);
      breakpointChecker();
    });
  }


};

export {sliderMobile};
