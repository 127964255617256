const sliderFull = () => {
  const sliders = document.querySelectorAll('.n-js-slider-full');

  if (sliders) {
    sliders.forEach((slider) => {
      const initSwiper = () => {
        const container = slider.querySelector('.swiper-container') || slider;
        const btns = slider.querySelectorAll('.n-js-slider-controls');
        const id = '#' + container.id;
        let paginationOpt = false;
        let slidesAmount;
        if (slider.querySelector('.n-flex-list--3')) {
          slidesAmount = 3;
        } else if (slider.querySelector('.n-flex-list--4')) {
          slidesAmount = 4;
        }
        if (container.classList.contains('n-js-slider-mobile-pagination')) {
          paginationOpt = {
            el: '.n-js-slider-mobile-pagination',
            type: 'bullets',
            clickable: true,
          };
        }

        let swiper = new Swiper(id, {
          loop: true,
          observeParents: true,
          observer: true,
          spaceBetween: 16,
          navigation: {
            prevEl: btns[0],
            nextEl: btns[1],
          },
          pagination: paginationOpt,
          speed: 400,
          slidesPerView: 1,
          breakpoints: {
            767: {
              spaceBetween: 30,
              slidesPerView: 2,
              pagination: false,
            },
            1023: {
              loop: false,
              spaceBetween: 30,
              slidesPerView: 2,
            },
            1281: {
              spaceBetween: 30,
              loop: false,
              slidesPerView: slidesAmount,
            },
          },
        });
      };

      initSwiper();
    });
  }
};

export {sliderFull};
