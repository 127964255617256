const accordion = () => {
  const accordions = document.querySelectorAll('.n-js-accordion');

  if (!accordions) return;

  class Accordion {
    constructor(elem) {
      this.block = elem;
      this.items = this.block.querySelectorAll('.n-accordion__item');
      this.headers = this.block.querySelectorAll('.n-accordion__header');

      this.headers.forEach((i) => {
        i.addEventListener('click', this.toggle.bind(this, i), false);
      });
    }

    toggle(item, e) {
      if (e.target.closest('.n-accordion--arrows')) {
        let activeItem = this.block.querySelector('.n-accordion__item--active');
        if (activeItem && item.parentNode !== activeItem) {
          activeItem.classList.remove('n-accordion__item--active');
        }
      }
      item.parentNode.classList.toggle('n-accordion__item--active');
    }
  }

  accordions.forEach((item) => {
    new Accordion(item);
  });
};

export {accordion};

