import {accordion} from './modules/accordion';
import {calendar} from './modules/calendar';
import {filter} from './modules/filter';
import {initImageCompare} from './modules/imageCompare';
import {initModals} from './modules/init-modals';
import {map} from './modules/map';
import {select} from './modules/select';
import {showMore} from './modules/show-more';
import {sidebarSwitcher} from './modules/sidebar-switcher';
import {sliderFull} from './modules/slider-full';
import {sliderMobile} from './modules/slider-mobile';
import {switcher} from './modules/switcher';
import {tabs} from './modules/tabs';
import {telMask} from './modules/tel-mask';
import {toggleBtn} from './modules/toggle-btn';
import {video} from './modules/video';


initModals();
video();
showMore();
accordion();
tabs();
telMask();
calendar();
sliderMobile();
sliderFull();
select();
switcher();
map();
filter();
toggleBtn();
initImageCompare();
sidebarSwitcher();
