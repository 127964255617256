const calendar = () => {
  const calendars = document.querySelectorAll('.n-js-calendar');

  if (calendars) {
    calendars.forEach((calendar) => {
      const init = () => {
        const id = '#' + calendar.id;

        flatpickr(id, {
          disableMobile: 'true',
        });
      };

      init();
    });
  }
};

export {calendar};
