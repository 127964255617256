const select = () => {
  const selects = document.querySelectorAll('.n-select');

  if (!selects) return;

  class Select {
    constructor(elem) {
      this.select = elem;
      this.input = this.select.querySelector('.n-select__input');
      this.list = this.select.querySelector('.n-select__list');
      this.options = null;
      this.placeholder = this.select.querySelector('.n-select__placeholder');
      this.preChosen = this.select.dataset.chosen;
      this.closeBinded = this.close.bind(this);

      this.input.addEventListener('click', this.toggle.bind(this), false);
      this.placeholder.addEventListener('click', this.toggle.bind(this), false);

      this.setOptions();
    }

    setOptions() {
      this.options = this.list.querySelectorAll('.n-select__option');
      let length = this.options.length;
      for (let i = 0; i < length; i++) {
        this.options[i].addEventListener('click', this.choose.bind(this, this.options[i]), false);
      }
    }

    toggle() {
      this.select.classList.toggle('n-select--active');
      if (this.list.dataset.changed) {
        this.setOptions();
      }

      if (this.select.classList.contains('n-select--active')) {
        document.addEventListener('click', this.closeBinded);
      }
      else {
        document.removeEventListener('click', this.closeBinded);
      }
    }

    close(e) {
      if (e.target !== this.select && !this.select.contains(e.target)) {
        this.select.classList.remove('n-select--active');
      }
    }

    choose(opt) {
      this.setActiveOption(opt);

      this.input.classList.add('n-select__input--chosen');
      this.select.classList.remove('n-select--active');

      if (opt.querySelector('.n-select__option-text')) {
        opt = opt.querySelector('.n-select__option-text');
      }

      if (this.preChosen) {
        this.input.value = `${this.preChosen}: ${opt.textContent}`;
      } else {
        this.input.value = opt.textContent;
      }

      this.input.dispatchEvent(new Event('change'));
    }

    setActiveOption(opt) {
      let activeOpt = this.select.querySelector('.n-select__option--active');
      if (activeOpt) {
        activeOpt.classList.remove('n-select__option--active');
      }
      opt.classList.add('n-select__option--active');

      this.select.parentNode.classList.remove('invalid');
    }
  }

  selects.forEach((item) => {
    new Select(item);
  });
};

export {select};
