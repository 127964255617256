const showMore = () => {
  const showMoreBlocks = document.querySelectorAll('.n-js-show-more');

  if (!showMoreBlocks) return;

  class ShowMore {
    constructor(elem) {
      this.block = elem;
      this.content = this.block.querySelector('.n-js-show-more-content');
      this.btn = this.block.querySelector('.n-js-show-more-btn');
      this.scrollTop = 0;

      this.btn.addEventListener('click', this.toggle.bind(this), false);
      this.btn.addEventListener('click', this.handleScrollPosition.bind(this), false);
    }

    toggle() {
      this.block.classList.toggle('n-show-more--active');
    }

    handleScrollPosition() {
      if (this.block.classList.contains('n-show-more--active')) {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      } else {
        const newScroll = window.pageYOffset || document.documentElement.scrollTop;

        if (newScroll > this.scrollTop) {
          window.scrollTo({
            top: this.scrollTop,
            behavior: 'auto',
          });
        } else {
          window.scrollTo({
            top: this.newScroll,
            behavior: 'auto',
          });
        }
      }
    }
  }

  showMoreBlocks.forEach((item) => {
    new ShowMore(item);
  });
};

export {showMore};
