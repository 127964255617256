const map = () => {
  const mapBlock = document.querySelector('#map');
  const breakpoint = window.matchMedia('(max-width: 1024px)');

  if (!mapBlock) {
    return;
  }

  function init() {
    let myMap;
    let coords = [55.900436, 37.632089];

    myMap = new ymaps.Map(mapBlock, {
      center: [55.753215, 37.622504],
      zoom: 9,
      controls: [],
    });

    mapBlock.dataset.center ? myMap.setCenter(mapBlock.dataset.center.split(','), 9) : null;
    mapBlock.dataset.coords ? coords = mapBlock.dataset.coords.replace(/\s/g,'').split(';') : null;

    if (mapBlock.dataset.zoomControls === 'true') {
      myMap.controls.add('zoomControl');
    }

    const pinUrl = mapBlock.dataset.img || './img/svg/map-pin.svg';

    const initMarks = function(coordinates) {
      let myPlacemark = new ymaps.Placemark(coordinates, {}, {
        iconLayout: 'default#image',
        iconImageHref: pinUrl,
        iconImageSize: [40, 40],
        iconImageOffset: [-20, -40],
      });
      myMap.geoObjects.add(myPlacemark);
    };

    if (mapBlock.dataset.coords) {
      coords.forEach((item) => {
        initMarks(item.split(','));
      });
    }
    else {
      initMarks(coords);
    }


    const breakpointChecker = function() {
      if (breakpoint.matches === true) {
        myMap.behaviors.disable('drag'); // убирает скролл по карте
        myMap.behaviors.disable('scrollZoom');
      }
      else {
        myMap.behaviors.enable('drag');
        myMap.behaviors.enable('scrollZoom');
      }
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  }

  ymaps.ready(init);


};

export {map};
