const telMask = () => {
  const phones = document.querySelectorAll('.n-form input[type=tel]');

  if (!phones) {
    return;
  }

  phones.forEach((phone) => {
    const initMask = () => {
      const maskString = '+{7} (000) 000-00-00';

      const phoneMask = new IMask(phone,
        {
          mask: maskString,
          lazy: true,
          placeholderChar: '_', // defaults to '_'
          country: 'Russia',
        });
    };

    initMask();

  });


};

export {telMask};
