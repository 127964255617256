import {setupModal} from '../utils/modal';


// аргументы setupModal(modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock)
// возможна инициализация только с первыми аргументом,
// если вам нужно открывать модалку в другом месте под какими-нибудь условиями
const initModals = () => {

  const modals = document.querySelectorAll('.modal');

  if (!modals) {
    return;
  }

  window.addEventListener('load', () => {
    // фикс для редких случаев, когда модалка появляется при загрузке страницы
    modals.forEach((el) => {
      setTimeout(() => {
        el.classList.remove('modal--preload');
      }, 100);
    });

    modals.forEach((modal) => {
      const targetModal = modal.dataset.targetModal;
      const modalBtns = document.querySelectorAll(`[data-modal=${targetModal}]`);
      setupModal(modal, false, modalBtns);
    });
  });
};

export {initModals};
