const filter = () => {
  const filters = document.querySelectorAll('.n-js-filter');

  if (!filters) {
    return;
  }

  class Filter {
    constructor(el) {
      this.el = el;
      this.fields = this.el.querySelectorAll('[data-filter]');
      this.items = this.el.querySelectorAll('[data-filter-labels]');
      this.itemsContainer = this.el.querySelector('.n-filter__catalog');
      this.message = this.el.querySelector('.n-filter__message');
      this.scrollBinded = null;
      this.filtered = [];
      this.activeClass = 'n-select__option--active';

      for (let f of this.fields) {
        f.addEventListener('click', this.filter.bind(this));
      }

      this.setAnimation(this.items);
    }

    filter() {
      this.filtered = [];
      let data = [];
      let chosenFields = this.el.querySelectorAll(`.${this.activeClass}`);

      const arrayContainsArray = function (superset, subset) {
        return subset.every(function (value) {
          return (superset.indexOf(value) >= 0);
        });
      };

      const find = (item) => {
        for (let i of chosenFields) {
          i.dataset.filter.split(' ').forEach((i) => data.push(i));
        }
        return arrayContainsArray(item.dataset.filterLabels.split(' '), data);
      };

      for (let i of this.items) {
        if (find(i)) {
          this.filtered.push(i);
        }
        i.classList.remove('shown');
      }

      this.output();
    }

    output() {
      window.removeEventListener('scroll', this.scrollBinded);

      if (this.filtered.length === 0) {
        this.itemsContainer.innerHTML = '';
        if (this.message) {
          this.message.classList.remove('hidden');
        }
      } else {
        if (this.message && !this.message.classList.contains('hidden')) {
          this.message.classList.add('hidden');
        }

        this.itemsContainer.innerHTML = Array.prototype.reduce.call(this.filtered, (html, node) => {
          return html + (node.outerHTML || node.nodeValue);
        }, '');

        this.setAnimation();
      }
    }

    calcTop(i) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return Math.round(i.getBoundingClientRect().top + scrollTop);
    }

    setAnimation(items) {
      items = this.el.querySelectorAll('[data-filter-labels]');
      this.showItems(items);
      this.scrollBinded = this.scrollLoad.bind(this, items);
      window.addEventListener('scroll', this.scrollBinded);
    }

    showItems(items) {
      for (let i of items) {
        let top = this.calcTop(i);
        if (window.innerHeight > top + i.offsetHeight) {
          i.classList.add('shown');
        }
      }
    }

    scrollLoad(items) {
      const isBlockIntoView = function (block) {
        return block.getBoundingClientRect().top <= document.documentElement.clientHeight;
      };

      for (let i of items) {
        if (isBlockIntoView(i) && !i.classList.contains('shown')) {
          i.classList.add('shown');
        }
      }
    }
  }

  for (let i of filters) {
    new Filter(i);
  }
};

export {filter};

