const video = () => {
  const embedVideosNodes = document.querySelectorAll('.js-video');
  if (!embedVideosNodes.length) {
    return;
  }

  class EmbedVideo {
    constructor(element) {
      this.element = element;
      this.videoWrapperNode = this.element.querySelector('.js-video-wrapper');
      this.iframeNode = this.videoWrapperNode.querySelector('iframe');
      this.playBtnNode = this.element.querySelector('.js-video-play');

      this.dataset = this.videoWrapperNode.dataset;
      this.videoId = this.dataset.id;
      this.videoSrc = this.dataset.src;
      this.opacity = this.dataset.previewOpacity;

      this.player = null;

      this.setVideoStyles();
      this.setListeners();
    }

    setListeners() {
      window.addEventListener('resize', () => {
        this.setVideoStyles();
      });

      this.playBtnNode.addEventListener('click', () => {
        this.showVideo();
      });
    }

    showVideo() {
      this.iframeNode.setAttribute('src', this.videoSrc);
      setTimeout(() => {
        this.element.classList.add('is-play');
      }, 500);
    }

    hideVideo() {
      this.element.classList.remove('is-play');
      this.iframeNode.setAttribute('src', '');
    }

    setVideoStyles() {
      let opacity = 0;

      if (this.opacity) {
        opacity = this.opacity;
      }

      this.videoWrapperNode.setAttribute('style', `background-color: rgba(0, 0, 0, ${opacity});`);
    }
  }

  window.embedVideosList = [];
  embedVideosNodes.forEach((item) => {
    window.embedVideosList.push(new EmbedVideo(item));
  });
};

export {video};
