const tabs = () => {
  const tabContainers = document.querySelectorAll('.n-js-tabs');

  if (!tabContainers) {
    return;
  }

  class Tabs {
    constructor(elem) {
      this.block = elem;
      this.contentBlocks = this.block.querySelectorAll('.n-js-tabs-content');
      this.tabs = this.block.querySelectorAll('.n-js-tabs-item');
      this.tabCopy = this.block.querySelector('.n-js-tabs-copy');
      this.activeClass = 'n-js-tabs-content--active';
      this.openedClass = 'n-show-more--active';
      this.isAccordion = elem.classList.contains('n-js-tabs-accordion');
      this.isInTabs = this.block.classList.contains('n-js-tabs--in-tabs');
      this.accordionSelect = this.block.querySelector('.n-js-tabs-accordion-select');

      if (this.isInTabs) {
        this.contentBlocks = this.block.querySelectorAll(':scope > .n-js-tabs-content');
        this.tabs = this.block.querySelectorAll(':not(.n-js-tabs-content) > .n-js-tabs-item');
      }

      if (!this.isAccordion) {
        this.tabs.forEach((i) => {
          i.addEventListener('click', this.choose.bind(this, i), false);
        });
      } else {
        this.setParams();
      }
    }

    setParams() {
      const list = this.accordionSelect.querySelector('.n-select__list');
      const headers = this.block.querySelectorAll('.n-accordion__title');
      const contentWrap = this.block.querySelector('.n-js-tabs-content-wrap');

      contentWrap.innerHTML = this.renderContent(this.contentBlocks);
      this.contentBlocks = contentWrap.querySelectorAll('.n-js-tabs-content');
      list.innerHTML = Array.from(headers).map((item) => this.renderOption(item)).join('');
      this.tabs = list.querySelectorAll('.n-js-tabs-item');
      this.setSelectOption(this.tabs[0].textContent);
      const firstOption = this.accordionSelect.querySelector('.n-select__option');
      firstOption.classList.add('n-select__option--active');
      this.contentBlocks[0].classList.add(this.activeClass);

      this.tabs.forEach((i) => {
        i.addEventListener('click', this.choose.bind(this, i), false);
      });
    }

    renderOption(item) {
      let val = item.textContent.charAt(0).toUpperCase() + item.textContent.slice(1);
      let data = item.dataset.tabContent;
      return `<li class='n-select__option n-js-tabs-item' data-tab-content=${data}>${val}</li>`;
    }

    renderContent(item) {
      return Array.prototype.reduce.call(item, function (html, node) {
        return html + (node.outerHTML || node.nodeValue);
      }, '');
    }

    setSelectOption(val) {
      const input = this.accordionSelect.querySelector('.n-select__input');
      const placeholder = this.accordionSelect.querySelector('.n-select__placeholder');

      let value = val.charAt(0).toUpperCase() + val.slice(1);

      input.value = value;
      placeholder.textContent = value;
      input.classList.add('n-select__input--chosen');

    }

    choose(tab) {
      if (this.isAccordion) {
        let activeOption = this.accordionSelect.querySelector('.n-select__option--active');
        activeOption ? activeOption.classList.remove('n-select__option--active') : tab.classList.add('n-select__option--active');

        this.setSelectOption(tab.textContent);
      }
      let activeTab = this.block.querySelector(`.${this.activeClass}`);
      if (this.isInTabs) activeTab = this.block.querySelector(`:scope > .${this.activeClass}`);
      let isOpen = false;

      if (activeTab.classList.contains(this.openedClass)) {
        isOpen = true;
      }

      this.contentBlocks.forEach((i) => {
        if (i.dataset.tab === tab.dataset.tabContent) {

          if (activeTab) {
            activeTab.classList.remove(this.activeClass);
          }

          i.classList.add(this.activeClass);

          if (isOpen === true) {
            i.classList.add(this.openedClass);
          } else {
            i.classList.remove(this.openedClass);
          }

          if (this.tabCopy) {
            this.tabCopy.textContent = i.dataset.tab;
          }
        }
      });
    }
  }

  tabContainers.forEach((item) => {
    new Tabs(item);
  });
};

export {tabs};
