const sidebarSwitcher = () => {
  const sidebarSwitchers = document.querySelectorAll('.n-js-sidebar-switcher');

  if (sidebarSwitchers.length) {
    for (let item of sidebarSwitchers) {
      const tabsContainer = item.parentElement.querySelector('.n-js-sidebar-tabs-list');
      const switcherInputs = item.querySelectorAll('input[name="model"][type="radio"]');

      const switchTabs = (activeTab, target) => {
        activeTab.classList.remove('active');
        target.classList.add('active');
      };

      const switcherChangeHandler = (evt) => {
        const targetTab = tabsContainer.querySelector(`[data-tab="${evt.target.value}"]`);
        const activeTab = tabsContainer.querySelector('.active');

        switchTabs(activeTab, targetTab);
      };

      switcherInputs.forEach((input) => {
        input.addEventListener('change', switcherChangeHandler);
      });
    }
  }
};

export {sidebarSwitcher};
